@import 'site.variables.less';
@import 'site.mixins.less';

body, html {
    overscroll-behavior: none !important;
}

body {
    font-family: @font-family;
    // put in because vertical scroll bars made content shift
    margin-right: calc(100% - 100vw);
    overflow-x: hidden;
    color: @black;
    font-size: @body-font-size;
    padding-top: @header-height;
}

body.login {
    background-color: @dark-blue;
    padding-top:0;
}

body.home, body.faqs, body.glossary  {
    background-image: url('./assets/pin_bg.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    height: 100vh;
    background-attachment: fixed;
}

body.mapping {
    overflow: hidden;
}

body.account {
    background-color: @elephants-breath;
}

body.orders {
    &.overflowed-x {
        overflow-x: auto;
    }
}

#home-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

body.overflowed-y #home-container {
    display: block;
    align-items: unset;
    justify-content: unset;
    height: auto;
}

body.mobile-menu-open {
    overflow-y: hidden !important;
}

// preload images
body::after{
    position: absolute;
    width: 0; 
    height: 0; 
    overflow: hidden;
    z-index: -1;
    // load images
    content: url('./assets/orientation.svg') url('./assets/reorder.svg') url('./assets/dark_blue_bg.png') url('./assets/pin_bottom.svg') url('./assets/pin_top.svg');
 }

textarea {
    resize: none;
}

a {
    cursor: pointer !important;
    color: @blue;
    text-decoration: underline;

    &:focus, &:hover {
        color: @dark-blue;
    }

    &:focus-visible {
        outline: none !important;
    }
}

div:focus-visible {
    outline: none !important;
}

.custom-breadcrumb {
    .h2();
    margin-top: 20px;
    background-color: transparent;

    .crumb-separator {
        color: @dark-grey;
        padding-left: 16px;
        padding-right: 16px;
    }
}

input[type=checkbox]:focus, input[type=file]:focus, input[type=radio]:focus {
    outline: none;
}

/* remove autofill bg colour */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px @white inset !important;
    box-shadow: 0 0 0 30px @white inset !important;
}

input[disabled]:-webkit-autofill{
    -webkit-box-shadow: 0 0 0 30px @grey inset !important;
    box-shadow: 0 0 0 30px @grey inset !important;
}

h1, .h1 {
    .h1();
}

h2, .h2 {
    .h2();
}

h3, .h3 {
    .h3();
}

h4, .h4 {
    .h4();
}

ul {
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

.warning {
    .regular-font();
    color: @red;
}

.success {
    .regular-font();
    color: @green;
}


/* Buttons & Inputs */

.invalid-feedback {
    color: @red;
    margin-top: 5px;
    display: none;
}

// display validation message on blur regardless of form submission
.invalid-feedback.onblur {
    display: inherit;
}

.ng-submitted .invalid .invalid-feedback, .ng-submitted.ng-invalid .invalid-feedback {
    display: inline-block;
}

.input-form {
        
    .form-group {
        margin-top: @form-group-margin-top;
    }
    
    .form-control:not(input[type=checkbox],textarea) {
        height: @form-control-height;
    }

    .btn-block {
        height: @form-control-height;
    }

    input, select, textarea {
        cursor: pointer;

        &.ng-dirty.ng-invalid, &.error {
            border-color: @red;
        }
    }
}

.form-control, input {
    border-color: @grey;
    box-shadow: none;
}

.form-control:focus, input:focus {
    border-color: @blue;
    box-shadow: none;
}

.form-control[disabled]:focus, input[disabled]:focus, button[disabled], .form-control[disabled] {
    border-color: @grey !important;
    background-color: @grey !important;
    box-shadow: none;
}

.btn {
    .btn();
}

.btn-default.focus, .btn-default:focus, .btn-default:active {
    border-color: @grey;
    box-shadow: none;
}

.btn-primary {
    .btn-primary();
}

// secondary
.btn-secondary {
    .btn-secondary();
}

// tertiary
.btn-tertiary {
    background-color: @tertiary-bg-color;
    border-color: @tertiary-border-color;
    color: @tertiary-font-color;
}

.btn-tertiary:hover, .btn-tertiary:focus {
    background-color: @tertiary-bg-hover-color;
    border-color: @tertiary-border-hover-color;
    color: @tertiary-font-hover-color;
}

// negative
.btn-negative {
    background-color: @negative-bg-color;
    border-color: @negative-border-color;
    color: @negative-font-color;
}

.btn-negative:hover, .btn-negative:focus {
    background-color: @negative-bg-hover-color;
    border-color: @negative-border-hover-color;
    color: @negative-font-hover-color;
}

.btn-negative:focus {
    background-color: @negative-bg-color;
}


//disabled
button[disabled], .btn:disabled, .btn-primary:disabled, .btn-secondary:disabled, .btn-negative:disabled,
button[disabled]:hover, .btn:disabled:hover, .btn-primary:disabled:hover, .btn-secondary:disabled:hover, .btn-negative:disabled:hover {
    .disabled-button();
}

.caret {
    border-bottom: none !important;
    text-decoration: none !important;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

// close

button.close {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.close {
    margin-top: -2px;
    float: right;
    line-height: 1;
    color: @dark-grey;
    opacity: 1;
    outline: none;

    span {
        cursor: cursor;
    }
}

.close:focus, .close:hover {
    color: @dark-grey;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=80);
    opacity: .8;
    background-color: transparent !important;
}

.btn-block {
    display: block;
    width: 100%;
}

/* Buttons End */

label, .form-label {
    color: @dark-grey !important;
    .p4();

    &.required {
        &:after {
            content: "\2a"; 
            font-family: @icon-web-font;
            font-style: normal;
            color: @red;
            padding-left: 5px;
            font-size: 10px;
            vertical-align: top;
        }
    }
}

.form-control-display {
    .p1();
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 0;
    line-height: 1.42857143;
    border: none;
}

input::-ms-clear {
    display: none;
}

hr {
    border-top: 1px solid @grey;
}


/* Drag & Drop of current item appended to DOM */

.btn-current-plot-drag {
    .command-button();
    width: 54px !important;
    border: none !important;
}

.cdk-drag-preview {
    box-sizing: border-box;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);

    background-color: @white;

    .btn {
        visibility: hidden;
    }

    .btn-current-plot-drag {
        visibility: visible;
        background-color: transparent !important;
    }
}

/* user select */
label, span, h1, h2, h3, p, div {
    user-select: none;
}

.selectable {
    user-select: text;
    cursor: auto !important;
}

/* dropdowns */
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: calc(@sticky-z-index + 1);
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px #0000002d;

    & > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: 400;
        line-height: 1.42857143;
        color: @black;
        white-space: nowrap;

        &:hover {
            background-color: transparent;
        }

        &.btn-filter-link {
            display: inline-block;
            color: @blue;
            cursor: pointer !important;
            color: @blue;
            text-decoration: underline;
            padding: 0 15px;
        
            &:focus, &:hover {
                color: @dark-blue;
            }
        
            &:focus-visible {
                outline: none !important;
            }
        }
    }
}

.open .dropdown-menu {
    display: block;
}

.dropdown-control {
    display: inline-block;
    position: relative;

    .filter-toggle {
        padding-left: 5px;
        padding-right: 5px;
        color: @dark-grey;

        &:hover, &.active {
            color: @blue;
        }
    }    

    &.disabled {
        .filter-toggle {
            cursor: not-allowed !important;

            &:hover, &.active {
                color: @dark-grey;
            }

            i::after {
                content: "\2f";
                font-family: "Font Awesome 6 Pro";
                font-style: normal;
                margin-left: -0.75em;
                color: @dark-grey;
            }
        }
    }

    &.open .filter-toggle {
        color: @blue;
    }

    .btn:not(.btn-filter) {
        background-color: @white;
        border: 1px solid @grey;
        border-radius: 2px;

        i {
            color: @dark-grey;
            margin-left: 5px;
        }

        margin-left: 10px;
        height: @order-button-height;
    }

    .dropdown-menu {
        margin-left: 10px;
        margin-top: 1px;
        width: 200px;
        padding-inline-start: 0;
        padding-inline-end: 0;
        padding: 0;
        margin-left: 15px;

        &.dropdown-menu-right {
            left: auto;
            right: 0;
            margin-top: 0;
        }

        a:not(.btn-filter-link), button:not(.btn-filter), label {
            padding-left: 22px;
            padding-right: 22px;
            transition: background-color 0.3s ease-out;
            background-color: @white;
        }

        a:not(.btn-filter-link):hover, a:not(.btn-filter-link):active, button:not(.btn-filter):hover, button:not(.btn-filter):active {
            background-color: @grey;
        }

        li {
            float: none;
            padding: 5px;

            .filter-checkbox-wrapper {
                &:focus-visible {
                    outline: none !important;
                }

                vertical-align: middle;
                cursor: pointer;
                width: 100%;
                font-size: @p1-font-size;
                font-weight: @regular-font-weight;
                color: @black;
                float: none;
                position: relative;
            
                input[type=checkbox] {
                    position: absolute;
                    opacity: 0.1;
                    left: 3px;
                    appearance: none;
                }
            
                span {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    border-radius: @border-radius @border-radius;
                    border: 1px solid @grey;
                    background-color: @white;
                    vertical-align: bottom;
                    margin-right: 10px;
                    line-height: @p1-font-size;
                    
                    i {
                        display: none;
                        position: relative;
                        color: @white;    
                        padding: 2px;
                        font-size: 13px;
                    }
                }
            
                &.checked span {
                    background-color: @blue;
                    border-color: @blue;
                    text-align: center;
            
                    i {
                        display: inline-block;
                    }
                }
            }

            &.filter-header {
                font-weight: @bold-font-weight;
                text-align: left;
                padding-left: 22px;
                font-size: @p1-font-size;
                padding-bottom: 15px !important;

                .close {
                    margin-right: 5px;
                    padding: 0;
                }
            }

            &.filter-footer {
                text-align: right;
                border-top: 1px solid @grey;
                padding-top: 15px !important;
                margin-top: 10px;
                padding-right: 15px;
            }
        }

        &.checkbox-list {
            
            li:first-of-type {
                padding-top: 15px !important;
            }

            li:last-of-type {
                padding-bottom: 15px !important;
            }
        }
    }
}


/* tables */
table {
    margin-bottom: 0 !important;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
}

.table > tbody > tr > td, .table > tfoot > tr > td, .table > thead > tr > td {
    .table-cell();
    .p1();
    color: @black;
    font-size: 14px;

    .yes-assets {
        color: @green !important;
        font-size: 22px;
    }
}

.table > tbody > tr.even > td {
    background-color: @elephants-breath;
}

.table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > th {
    .table-cell();
    border-bottom: 1px solid @grey !important;
    .p2();
    line-height: 1.42857143;
    text-align: left;
}

.table > thead > tr > th {
    background-color: @bluey-white;
    border-bottom: 1px solid @grey !important;
}

.table tr td:first-of-type, .table tr th:first-of-type {
    padding-left: 20px;
}


.table-fixed {
    width: 100%;
    position: relative;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.table-fixed thead tr th {
    position: sticky;
    position: -webkit-sticky;
    z-index: 1001;
    white-space: nowrap;
 /*   
    &::before {
        content: "";
        border-bottom: solid 1px #D9D9DE;
        display: block;
        position: absolute;
        width: 100%;
        margin-top: -16px;
        margin-left: -20px;
    }

    &::after { 
        content: "";
        border-bottom: solid 1px @grey;
        display: block;
        position: absolute;
        width: 100%;
        margin-top: 16px;
        margin-left: -20px;
    }
*/
    &:last-of-type::before  {
        width: 102%;
    }
}

.table-fixed tbody tr:nth-of-type(1) {
    td {
        border-top-color: transparent !important;
    }
}

.trans-table.table {
    margin-top: -10px;

    & > tbody > tr:last-child > td {
        padding-bottom: 0 !important;
    }
    
    & > thead > tr > th {
        background-color: transparent;
    }

    & tr td:first-of-type, & tr th:first-of-type {
        padding-left: 0;
    }
}

.table-hover tbody tr:hover {
    background-color: @whiteish;
    cursor: pointer !important;

    & > td {
        background-color: @whiteish;

        &:hover {
            cursor: pointer !important;
        }
    }
}

// tooltips
.looky-tooltip {
    .p5();
    display: block;
    position: absolute;
    background-color: @blue;
    height: @sidebar-button-height;
    padding: 10px;
    font-family: @font-family;
    margin-top: -34px;
    line-height: 24px;
    opacity: 0;
    transition: opacity 0.2s;
    left: -10000px;
    color: @white;
    z-index: calc(@sidebar-z-index + 2);
}

a.btn {
    .looky-tooltip {
        margin-top: -29px !important;
    }
}

.btn:hover {

    .looky-tooltip {
        left: 100%;
        opacity: 1;
    }
}

.btn:active {
    .looky-tooltip {
        left: -10000px;
        opacity: 0.5
    }
}

.looky-tooltip-container {
    position: relative;
}



.looky-secondary-tooltip {
    position: relative;

    .tooltip-text {
        display: none;
        height: 28px;
        background-color: @white;
        color: @black;
        text-align: center;
        padding: 6px;
        box-shadow: @box-shadow;
        position: absolute;
        z-index: 10000000;
        left: -25%;
        margin-top: 18px;
        font-weight: @regular-font-weight;
        letter-spacing: 1px;
        font-size: @body-font-size;

        &.tooltip-top {
            margin-top: -70px;
        }
    }

    &:hover .tooltip-text {
        display: block;
    }

    &.open:hover .tooltip-text {
        display: none;
    }
}

.input-group-btn.open {
    .tooltip-text {
        display: none;
    }
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

.input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap;
}


/* checkboxes */
.checkbox-size() {
    width: 20px;
    height: 20px;
}

.checkbox-style() {
    .checkbox-size();
    border-radius: @border-radius @border-radius;
    border: 1px solid @grey;
    background-color: @white;
    color: @white !important;
}

.checkbox-checked-style() {
    background-color: @blue;
    border-color: @blue;
}

.checkbox-wrapper {
    float: right;
    margin-top: -10px;

    input[type=checkbox] {
        position: relative;
        z-index: -9999;
        appearance: none;
    }

    span {
        margin-right:-3px;
        display: block;
        .checkbox-style();
        text-align: center;
        
        i {
            display: none;
            position: relative;
            top: -19px;
            color: @white;    
            padding: 2px; 
        }
    }

    &.checked span {
        .checkbox-checked-style();
        i {
            display: inline-block;
        }
    }
}

.looky-checkbox {
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    margin: 0;
    vertical-align: middle;
    .checkbox-size();
    position: relative;

    span:nth-of-type(1) {
        .checkbox-size();
        display: inline-block;
    }

    input[type=checkbox] { 
        position: absolute;
        left: 3px;
        opacity: 0.01; 
    }
    
    span:nth-of-type(1):before {
        font-family: @icon-web-font;
        .checkbox-style();
        display: inline-block;
        font: normal normal normal 14px/1 @icon-web-font;
        font-style: normal;
        font-weight: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: 15px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        padding-top: 2px;
        position: absolute;
    }

    &.invalid {
        span:nth-of-type(1):before {
            border: 1px solid @red;
        }
    }

    input[type=checkbox] + span:nth-of-type(1):before { content: "\f096"; } 

    input[type=checkbox]:checked + span:nth-of-type(1):before { 
        content: "\f00c"; 
        .checkbox-checked-style();
    }
    
    &.checked-minus input[type=checkbox]:checked + span:nth-of-type(1):before { 
        content: "\f068";
        line-height: 16.5px;
        .checkbox-checked-style();
    }
}

td .looky-checkbox, th .looky-checkbox {
    height: @table-cell-height;
    width: @table-cell-height;

    &:hover, &:focus, &:active {
        background-color: @grey;
    }

    span:nth-of-type(1) {
        margin-left: 15px;
        margin-top: 15px;
    }
}

.form-group {

    .looky-checkbox {
        width: auto;
        overflow: visible;

        span:nth-of-type(1) {
            margin-right: 20px;
        }

        span:nth-of-type(2) {
            vertical-align: super;
            font-weight: 500;
        }
    }
}


/* loading */
form.loading {
    .form-loading();
}


/* cookie script icon */
#cookiescript_badge  {
    left: 7px;
}  

body.orders {
    #cookiescript_badge  {
        display: none !important;
    }  
}

/* hotjar */
@media (max-width: @sm-breakpoint) {
    ._hj-widget-container {
        display: none;
    }
}
