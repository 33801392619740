@import 'site.variables.less';


/* Typography */
.regular-font() {
    font-weight: @regular-font-weight;
    font-style: normal;
    color: @black;
}

.italic-font() {
    .regular-font();
    font-style: italic;
}

.medium-font() {
    .regular-font();
    font-weight: @medium-font-weight;
}

.bold-font() {
    .regular-font();
    font-weight: @bold-font-weight;
}

.extra-bold-font() {
    .regular-font();
    font-weight: @extra-bold-font-weight;
}

.black-font() {
    .regular-font();
    font-weight: @black-font-weight;
}

h1, .h1 {
    .bold-font();
    font-size: @h1-font-size;
}

h2, .h2 {
    .bold-font();
    font-size: @h2-font-size;
}

h3, .h3 {
    .extra-bold-font();
    font-size: @h3-font-size;
}

h4, .h4 {
    .medium-font();
    font-size: @h4-font-size;
}

.p1 {
    .regular-font();
    font-size: @p1-font-size;
}

.p2 {
    .medium-font();
    font-size: @p2-font-size;
}

.p3 {
    .bold-font();
    font-size: @p3-font-size;
}

.p4 {
    .bold-font();
    font-size: @p4-font-size;
}

.p5 {
    .regular-font();
    font-size: @p5-font-size;
}

.p6 {
    .regular-font();
    font-size: @p6-font-size;
}


/* misc */

.clearfix() {
    float: none;
    clear: both;

    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

.card() {
    background: @white 0% 0% no-repeat padding-box; 
    box-shadow: 0px 3px 6px #C1C4DE66;
    padding: @card-padding-left @card-padding-top @card-padding-bottom @card-padding-right;
}

.card-header() {
    .h4();
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.overflow() {
    overflow-y: visible;
    overflow-y: overlay;
    overflow-x: hidden;
}

.table-cell() {
    padding: @table-cell-padding;
    // padding-left: 0;
    border-color: @grey;
    height: @table-cell-height;
    max-height: @table-cell-height;
    border-top: 1px solid @grey;
}

/* Buttons */

.btn() {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 6px 12px;
    line-height: 1.42857143;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-family: @font-family !important;
    border-radius: @btn-border-radius @btn-border-radius;
    text-decoration: none;
    .p3();

    &, &:focus, &:hover, &.active.focus, &.active:focus, &.focus, &:active.focus, &:active:focus {
        outline: none;
        box-shadow: none;
    }
}


.btn-primary() {
    background-color: @primary-bg-color;
    border-color: @primary-border-color;
    color: @primary-font-color;
    
    &:hover, &:focus, &:hover, &.active.focus, &.active:focus, &.focus, &:active.focus, &:active:focus {
        background-color: @primary-bg-hover-color;
        border-color: @primary-border-hover-color;
        color: @primary-font-hover-color;
    }
}

.btn-secondary() {
    background-color: @secondary-bg-color;
    border-color: @secondary-border-color;
    color: @secondary-font-color;
    
    &:hover, &:focus {
        background-color: @secondary-bg-hover-color;
        border-color: @secondary-border-hover-color;
        color: @secondary-font-hover-color;
    }
}

.disabled-button() {
    cursor: not-allowed !important;
    background-color: @disabled-bg-color;
    border-color: @disabled-border-color;
    color: @disabled-font-color !important;
    opacity: 1;

    i {
        color: @disabled-font-color !important;
    }
}

// e.g. buttons within the add/edit plot toolbar
.command-button() {
    border: none !important;
    border-left: 1px solid @grey !important;
    outline: none;
    font-size: @h2-font-size;
    width: @content-title-item-height !important;
    background-color: transparent;

    &:hover, &:active {
        background-color: @secondary-bg-hover-color;
        box-shadow: none;
    }

    &:focus {
        background-color: @secondary-bg-color;
    }

    &.content-title-last {
        padding-right: 12px;
        float: none;
        margin-right: 5px;
    }

    i {
        font-size: @command-button-icon-size;
    }

    &:disabled, &[disabled] {
        .disabled-button();
    }
}

.item-border() {
    border: 1px solid @grey;
}

.list-item-padding() {
    padding: 6px 12px 6px 12px;
}

.map-control-box-shadow() {
    box-shadow: 0px 3px 6px #455B632E;
}

.chunky-button {
    padding-left: @chunky-button-padding;
    padding-right: @chunky-button-padding;
}


// loading style and animation
@keyframes fadeIn { 
    from { opacity: 0.7; } 
}

.animate-flicker {
    animation: fadeIn 1s infinite;
    animation-direction: alternate;
}

.loading-style {
    opacity: 1;
    background-color: @grey !important;
    color: transparent !important;
    .animate-flicker();
    cursor: default !important;
    border-color: @grey !important;
}

// Form Loading
.form-loading() {
    a {
        visibility: hidden;
    }

    label, span {
        border: 1px solid @grey;
        border-radius: @border-radius;

        &:before {
            display: none;
        }
    }

    .input-group-btn {
        background-color: transparent !important;
        border-color: transparent !important;
    }

    h1, h2, h3, label, input, textarea, select, span, button, .radio-button-container, .layer-container, .asset-layer-container > *, .classification-container-row {
        .loading-style();

        &::placeholder {
            .loading-style();
        }

        &:after {
            .loading-style();
        }

        i {
            visibility: hidden;
        }
    }
}

.counter {
    background-color: @blue;
    color: #ffffff;
    font-family: @font-family;
    font-weight: bold;
    position: absolute;
    margin-left: -6px;
    margin-top: -11px;
    font-size: 11px;
    border: 1px solid @blue;
    height: 16px;
    min-width: 16px;
    border-radius: @border-radius;
    text-align: center;
    line-height: 14px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 1px;
    max-width: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
}


.hide-scrollbars() {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar { /* chrome */
        display: none;
    }
}